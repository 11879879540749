import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

export default {
  data: () => ({
    phoneMask: "(##) #####-####"
  }),
  filters: {
    formatDate: function(value) {
      return value
        .split("-")
        .reverse()
        .join("/");
    },
    formatDateTime: function(value) {
      if (!value) {
        return "";
      }

      return format(new Date(value), "dd/MM/yyyy - HH:mm");
    }
  },
  methods: {
    inputPhone(ev) {
      if (ev && ev.length > 14) {
        this.phoneMask = this.masks.nineDigitPhone;
      } else {
        this.phoneMask = this.masks.phone;
      }
    },
    parsePhone(phone) {
      phone.phone = phone.phone.trim();
      const phoneNumber =
        phone.phone.length === 9
          ? `${phone.phone.slice(0, -4)}-${phone.phone.slice(5, 9)}`
          : `${phone.phone.slice(0, -4)}-${phone.phone.slice(4, 9)}`;
      return `(${phone.areaCode}) ${phoneNumber}`;
    },
    parseDate(date, mask) {
      return format(new Date(date), mask, {
        locale: ptBR
      });
    },
    parseWeight(weight) {
      if ((weight + "").toUpperCase().indexOf("KG") >= 0) {
        return weight;
      }
      return `${weight.toLocaleString("pt-BR")} Kg`;
    },
    safeParseWeight(weight, defautMessage = "Não informado") {
      const weightParsedNumber = +weight;
      if (isNaN(weightParsedNumber)) {
        return defautMessage;
      }

      return `${weightParsedNumber.toLocaleString("pt-BR")} Kg`;
    }
  },
  computed: {
    masks() {
      return {
        phone: "(##) ####-####",
        nineDigitPhone: "(##) #####-####",
        cpf: "###.###.###-##",
        cnpj: "##.###.###/####-##",
        cnh: "###########",
        birthday: "##/##/####",
        cep: "#####-###",
        rg: "###########",
        zipCode: "#####-###"
      };
    }
  }
};
